import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import classes from "./mybannerlayout.module.scss";
import { useTranslation } from "react-i18next";
import {
  BANNER_TYPES,
  REGION_RADIUSES,
  CREDIT_PERIODS,
  GOOGLE_API_KEY,
} from "../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddBannerApiCall,
  actionMyListingApiCall,
  actionBannerTemplatesApiCall,
  actionApprovedOrganisationsApiCall,
  actionCityInfoApiCall,
  actionGetSuppliersApiCall,
} from "../../../Redux/Actions";
import { useNavigate, useParams } from "react-router-dom";
import PropertyCardSelectable from "../../layouts/PropertyCardSelectable";
import TemplateCardSelectable from "../../layouts/TemplateCardSelectable";
import RegionCardSelectable from "../../layouts/RegionCardSelectable";
import SupplierCardSelectable from "../../layouts/SupplierCardSelectable";
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
import logoIcon from "../../../assets/icons/smallLogo.svg";
import moment from "moment";
import { GoogleMap, useLoadScript, Marker, Circle } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Select from "react-select";
import { DateTime } from "luxon";
import { GetSuppliersApiCall } from "../../../API/API";
import PriceFormatter from "../../../utils/PriceFormatter";

const MyAddBannerLayout = () => {

  const libraries = ["places"];
  const mapContainerStyle = {
    width: "75%",
    height: "20vw",
  };

  const platform = localStorage.getItem("Platform");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env[`REACT_APP_${platform}_GOOGLE_API_KEY`],
    libraries,
  });


  const mapRef = useRef(null);
  const circleRef = useRef(null);

  const { t } = useTranslation();
  const params = useParams();
  const [queryParameters] = useSearchParams();
  const state_id = localStorage.getItem("user_id");
  const organisationListData = useSelector(
    (state) => state.CommonReducer.approvedOrganisationData
  );
  const stateData = useSelector((state) => state.CommonReducer);
  const myPropertyListingData = useSelector(
      (state) => state.CommonReducer.myPropertyListingData
    );
  const [error, setError] = useState();
  const [changedRadius, setChangedRadius] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [templateId, setTemplateId] = useState({ Leaderboard: 1, Mobile: 6 });
  const [id, setId] = useState();
  const [bannerBudget, setBannerBudget] = useState();

  const [submitted, setSubmitted] = useState(false);
  const stateRegions = useSelector(
    (state) => state.CommonReducer.defaultPropertyBannerRegion
  );
  const state = useSelector((state) => state.CommonReducer);
  const [regions, setRegions] = useState(queryParameters.get("object") != null ? stateRegions : []);

  const [searchData, setSearchData] = useState({
    address: "",
    address_data: state?.saveSearchBackground?.name
      ? state?.saveSearchBackground?.name
      : "",
  });
  const [addressSearch, setAddressSearch] = useState("");

  const [title, setTitle] = useState();
  const [quote, setQuote] = useState();

  const [center, setCenter] = useState({
    lng:
      typeof regions != "undefined" && typeof regions[0] != "undefined"
        ? regions[0].lng
        : 5.1214201,
    lat:
      typeof regions != "undefined" && typeof regions[0] != "undefined"
        ? regions[0].lat
        : 52.0907374,
  });

  const [zoom, setZoom] = useState(9)

  const chooseTemplate = (id, type) => {
    let tmpId = templateId;
    tmpId[type] = id;
    setTemplateId(tmpId);
  };
  const [selectedProperties, setSelectedProperties] = useState(
    queryParameters.get("object") != null
      ? [queryParameters.get("object")]
      : []
  );

  console.log("mpld", myPropertyListingData.rows);
  console.log("sp", selectedProperties);

  const [addBannerData, setAddBannerData] = useState({
    firstTime: true,
    agency_id: myPropertyListingData?.rows
      ?.filter((item) => item.id === parseInt(selectedProperties[0]))
      .map((item) => item.agency_id).join(""),
    title: `Doorlopende campagne ${
      typeof regions != "undefined" && typeof regions[0] != "undefined"
        ? regions[0].name
        : ""
    }`,
    quote: `Nieuw in de verkoop in ${myPropertyListingData?.rows
      ?.filter((item) => item.id === parseInt(selectedProperties[0]))
      .map((item) => item.city)
      .join("-")}`,
    region: "",
    radius: "",
    auto_select: true,
    template_id: 0,
    credits: 500,
    budgets: {},
    budget: 50,
    costs_per_day: 1.6,
    total_costs: 50,
    credit_period: "PER_DAY",
    selected_properties: [],
    banner_types: [],
    start_date: moment().format("Y-MM-DD"),
    end_date: moment().add(14, "d").format("Y-MM-DD"),
  });
  useEffect(() => {
    let data = { page: 1, per_page: "9999999" };
    let body = { data, navigate };
    dispatch(actionMyListingApiCall(body));
    dispatch(actionApprovedOrganisationsApiCall(body));
  }, [dispatch, navigate]);
  useEffect(() => {
    let data = { page: 1 };
    let body = { data, navigate };
    dispatch(actionBannerTemplatesApiCall(body));
  setBannerBudget(organisationListData[0]?.banner_budget);
  setTitle(addBannerData.title);
  setQuote(addBannerData.quote);
  }, []);
  const [suppliers, setSuppliers] = useState(typeof stateData.saveSuppliers != "undefined" && queryParameters.get("object") != null ? stateData.saveSuppliers.suppliers : []);
  const [totalCosts, setTotalCosts] = useState(0);
  const [costsPerDay, setCostsPerDay] = useState(0);
  const [totalViews, setTotalViews] = useState(0);
  const [viewsPerDay, setViewsPerDay] = useState(0);
  const [selectedSuppliers, setSelectedSuppliers] = useState({})

  const regionHandler2 = (option) => {
    if (typeof option != "undefined") {
      geocodeByAddress(option.value.name)
        .then((results) => getLatLng(results[0]))
        .then(async ({ lat, lng }) => {
          setCenter({ lat: lat, lng: lng });

          console.log("regions", regions)

          const newReg = {
              budget: 50,
              name: option.value.name,
              type: option.value.type,
              id: option.value.id,
              lat: lat,
              lng: lng,
              radius: 15,
            }

          console.log(newReg);

          const tmpRegions = [
            ...regions, newReg
          ];
          console.log("tmpRegions", tmpRegions);
          await updateSuppliers(tmpRegions);
          const arr = tmpRegions.map((item) => item.budget);
          console.log("arr", arr);
          const sum = arr.reduce(
            (accumulator, currentValue) =>
              parseInt(accumulator) + parseInt(currentValue),
            0
          );
          console.log(sum);
          setTotalCosts(sum);
          const sArr = addBannerData.start_date.split("-");
          if (sArr.length > 2) {
            const sDate = DateTime.local(
              parseInt(sArr[0]),
              parseInt(sArr[1]),
              parseInt(sArr[2])
            );
            const eArr = addBannerData.end_date.split("-");
            if (eArr.length > 2) {
              const eDate = DateTime.local(
                parseInt(eArr[0]),
                parseInt(eArr[1]),
                parseInt(eArr[2])
              );

              setCostsPerDay(
                Math.round(
                  (sum / eDate.diff(sDate, "days").toObject()["days"] +
                    Number.EPSILON) *
                    100
                ) / 100
              );
            }
          }

          setRegions([
            ...regions,
            {
              name: option.value.name,
              type: option.value.type,
              id: option.value.id,
              lat: lat,
              lng: lng,
              radius: 15,
              budget: 50,
            },
          ]);
        });

    }
    for (let j = 0; j < mapRef.current.props.children.length; j++) {
      const child = mapRef.current.props.children[j];
    }
  };

  const updateSuppliers = async (regs) => {
    let body = { regions: JSON.stringify(regs) };
    const suppliersThing = await GetSuppliersApiCall(body);

    // dispatch(actionGetSuppliersApiCall(body));
    setSuppliers(suppliersThing.data.suppliers);
    handleInitialValues(suppliersThing.data.suppliers, regs);
  }

  const handleChangeSupplier = (event, region, item) => {
    console.log("handleChangeSupplier");
    console.log(event.target.checked);
    console.log(item);
    let tmpSelected = selectedSuppliers;
    if (event.target.checked){
      tmpSelected[region].push(item.id);
    } else {
      tmpSelected[region] = tmpSelected[region].filter((value) => value !== item.id);
    }
    setSelectedSuppliers(tmpSelected)
    console.log(selectedSuppliers);
  };

  const handleChangeBudget = (event, item) => {
    console.log("handleChangeBudget");
    console.log(event.target.value);
    console.log(item);
    let regs = regions;
    console.log("regs", regs);
    for (let i = 0; i < regs.length; i++){
      if (regs[i].id === item.id) {
        regs[i].budget = event.target.value;
      }
    }
    setRegions(regs);
    console.log(regions);
    const arr = regs.map(item => item.budget);
    console.log(arr);
    const sum = arr.reduce(
      (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue),
      0
    );
    console.log(sum);
    setTotalCosts(sum);
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        setCostsPerDay(
          Math.round(
            (sum /
              eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );
      }
    }
  };

  const AddBannerHandler = () => {
    setSubmitted(true);
    let formData = new FormData();
    formData.append("id", params.id ? params.id : null);
    formData.append("user_id", state_id);
    formData.append("agency_id", id);
    formData.append("title", title);
    formData.append("quote", quote);
    formData.append("regions", JSON.stringify(regions));
    formData.append("suppliers", JSON.stringify(suppliers));
    formData.append("selectedSuppliers", JSON.stringify(selectedSuppliers));
    formData.append("radius", addBannerData.radius);
    formData.append("credits", addBannerData.credits);
    formData.append("budgets", JSON.stringify(addBannerData.budgets));
    formData.append("costs_per_day", costsPerDay);
    formData.append("total_costs", totalCosts);
    formData.append("credit_period", addBannerData.credit_period);
    formData.append("banner_types", JSON.stringify(["ONLINE"])); //JSON.stringify(selectedCheckboxes));
    formData.append("auto_select", false);
    formData.append("selected_properties", JSON.stringify(selectedProperties));
    formData.append("template_id", JSON.stringify(templateId));
    formData.append("start_date", addBannerData.start_date);
    formData.append("end_date", addBannerData.end_date);
    let body = {
      formData,
      navigate,
    };
    dispatch(actionAddBannerApiCall(body));
  };
   const changeRadius = (event) => {
    let tmpRegions = regions;
    let rad = 0;
    for (let i = 0; i < tmpRegions.length; i++){
      if (tmpRegions[i].id === parseInt(event.target.id.replace("radius_", ""))) {
          regions[i].radius = parseInt(event.target.value);
          rad = parseInt(event.target.value);
      }
    }
    if (rad <= 5) {
      setZoom(11);
    } else if (rad <= 10) {
      setZoom(10);
    } else if (rad <= 25) {
      setZoom(9);
    } else if (rad <= 50) {
      setZoom(8);
    } else {
      setZoom(7);
    }

    setRegions(tmpRegions);
    setChangedRadius(changedRadius + 1);
    updateSuppliers(regions);
  };
  const handleDeleteRegion = async (event, id) => {
    event.preventDefault();
    await updateSuppliers(regions.filter((item) => item.id !== id));

    setZoom(zoom);
    setRegions(regions.filter((item) => item.id !== id));
    setChangedRadius(changedRadius + 1);
  };

  const handleChangeDates = (event) => {
    addBannerData[event.target.id] = event.target.value;
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        let views = 0
        for (let i = 0; i < suppliers.length; i++) {
          const supplier = suppliers[i];
          const cpm = supplier.price_tables.map((ptItem) => ptItem.price_lines.map((plItem) => parseFloat(plItem.price)).reduce((accumulator, currentValue) => { return accumulator + currentValue; }, 0));
          const budget = addBannerData.budgets[supplier.id];
          views += Math.round((parseFloat(budget) / parseFloat(cpm) +
              Number.EPSILON) *
              100
          ) / 100;
          
        }
        setTotalViews(views*1000);
        setViewsPerDay(
          Math.round(
            (1000 * views /
              eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );

        setCostsPerDay(
          Math.round(
            (addBannerData.total_costs /
              eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );

      }
    }
  }

  const format = new Intl.NumberFormat("nl-NL", { maximumFractionDigits: 2 });

  const handleInitialValues = (sups, regs) => {
    console.log("IB");
    console.log("sups", sups);
    console.log("regs", regs);
    const keys = Object.keys(sups);
    let tmpHash = {};
    for (let i = 0; i < keys.length; i++) {
      console.log(keys[i]);
      console.log(sups[keys[i]]);
      tmpHash[keys[i]] = sups[keys[i]].map(item => item.id)
    }
    setSelectedSuppliers(tmpHash);
    console.log(selectedSuppliers);
    // const newRegs = regs.map(v => ({...v, budget: 50}))
    let newRegs = [];
    for (let i = 0; i < regs.length; i++){
      let item = regs[i];
      if (typeof item["budget"] === "undefined" || item["budget"] === null) {
        item["budget"] = 50;
      }
      newRegs.push(item);
    }
    setRegions(newRegs);
    console.log(regions)
    const arr = newRegs.map(item => item.budget);
    console.log(arr);
    const sum = arr.reduce(
      (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue),
      0
    );
    console.log(sum);
    setTotalCosts(sum);
    const sArr = addBannerData.start_date.split("-");
    if (sArr.length > 2) {
      const sDate = DateTime.local(
        parseInt(sArr[0]),
        parseInt(sArr[1]),
        parseInt(sArr[2])
      );
      const eArr = addBannerData.end_date.split("-");
      if (eArr.length > 2) {
        const eDate = DateTime.local(
          parseInt(eArr[0]),
          parseInt(eArr[1]),
          parseInt(eArr[2])
        );

        setCostsPerDay(
          Math.round(
            (sum /
              eDate.diff(sDate, "days").toObject()["days"] +
              Number.EPSILON) *
              100
          ) / 100
        );
      }
    }
    console.log(
      "calculated id",
      myPropertyListingData?.rows
        ?.filter((item) => item.id === parseInt(selectedProperties[0]))
        .map((item) => item.agency_id)[0]
    );
    setId(
      myPropertyListingData?.rows
        ?.filter((item) => item.id === parseInt(selectedProperties[0]))
        .map((item) => item.agency_id)[0]
    );
    console.log("id", id);

  };

  useEffect(() => {
      if (typeof queryParameters.get("object") === "undefined") {
        setSuppliers([]);
        setSelectedProperties([]);
      } else {
        updateSuppliers(regions);
      };
      dispatch(
        actionCityInfoApiCall({
          navigate,
          search: addressSearch ? addressSearch : "",
        })
      );
    }, [addressSearch]);

    

  return (
    <div className={classes.MyBannerLayoutMain}>
      <div className={classes.BannerFormMain}>
        {typeof queryParameters.get("object") === "undefined" && (
          <div className={classes.OrgSelect}>
            <p>{t("banner_please_select_which_organisation")}</p>
            {organisationListData?.length > 0 ? (
              organisationListData.map((item) => {
                return (
                  <div className={classes.OrganizationContainer}>
                    <p
                      onClick={() => {
                        setId(item?.id);
                        setBannerBudget(item?.banner_budget);
                        setAddBannerData((prevState) => ({
                          ...prevState,
                          agencyId: item?.id,
                        }));
                      }}
                      className={
                        id === item?.id
                          ? classes.OrgActive
                          : organisationListData?.length === 1
                          ? classes.OrgActive
                          : classes.Org
                      }
                    >
                      {item?.name}, {item?.city}
                    </p>
                  </div>
                );
              })
            ) : (
              <>
                <div className={classes.OrganizationContainer}>
                  <p
                    onClick={() => {
                      setId(organisationListData?.id);
                      setAddBannerData((prevState) => ({
                        ...prevState,
                        agencyId: organisationListData?.id,
                      }));
                    }}
                  >
                    {organisationListData &&
                      `${organisationListData?.name}, ${organisationListData?.city}`}
                  </p>
                </div>
              </>
            )}
          </div>
        )}
        <div className={classes.FloatingButton}>
          <button
            className={!submitted ? classes.saveButton : classes.disabledButton}
            onClick={AddBannerHandler}
          >
            {t("banner_activate")}
          </button>
        </div>
        <div className={classes.BannerTitle}>
          <p>{t("banner_title")}</p>
          <div className={classes.InputFieldBox}>
            <input
              type="text"
              name="title"
              defaultValue={addBannerData.title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </div>
        </div>
        <div className={classes.BannerTitle}>
          <p>{t("banner_quote")}</p>
          <div className={classes.InputFieldBox}>
            <input
              type="text"
              name="quote"
              defaultValue={addBannerData.quote}
              onChange={(event) => setQuote(event.target.value)}
            />
          </div>
        </div>
        <div className={classes.BannerDateWrap}>
          <div className={classes.BannerStartDate}>
            <p>{t("banner_start_date")}</p>
            <input
              type="date"
              name="start_date"
              id="start_date"
              defaultValue={addBannerData.start_date}
              onChange={(event) => {
                handleChangeDates(event);
              }}
              min={moment().format("Y-MM-DD")}
              data-date-format="DD MMMM YYYY"
            />
          </div>
          {error?.propertyAvailableDate && (
            <span className={`${classes.errorText}`}>
              {error.propertyAvailableDate}
            </span>
          )}
          <div className={classes.BannerStartDate}>
            <p>{t("banner_end_date")}</p>
            <input
              type="date"
              name="end_date"
              id="end_date"
              defaultValue={addBannerData.end_date}
              onChange={(event) => {
                handleChangeDates(event);
              }}
              min={moment().format("Y-MM-DD")}
              data-date-format="DD MMMM YYYY"
            />
          </div>
          <div className={classes.BannerStartDate}></div>
          {error?.propertyAvailableDate && (
            <span className={`${classes.errorText}`}>
              {error.propertyAvailableDate}
            </span>
          )}
        </div>
        <div className={classes.BannerRegionWrap}>
          <div className={classes.InputFieldBox}>
            <p>{t("banner_region")}</p>
          </div>
          <Select
            className={classes.basic_single}
            classNamePrefix="select"
            placeholder={t("area_neighbourhood_address_etc")}
            defaultValue={
              state?.saveSearchBackground &&
              Object.keys(state?.saveSearchBackground).length > 0 &&
              state?.saveSearchBackground.name
                ? {
                    label: `${state?.saveSearchBackground.name}(${t(
                      state?.saveSearchBackground.type
                    )})`,
                    value: {
                      id: state?.saveSearchBackground.id,
                      type: state?.saveSearchBackground.type,
                      image: state?.saveSearchBackground.image,
                      name: state?.saveSearchBackground.name,
                    },
                  }
                : null
            }
            style={{ background: "#fbfbfb" }}
            isClearable={false}
            isSearchable={true}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            name="address_data"
            onInputChange={(data) => setAddressSearch(data)}
            options={
              state?.saveCityInfoData && state?.saveCityInfoData?.length > 0
                ? state?.saveCityInfoData?.map((p) => {
                    return {
                      label: `${p.name} (${t(p.type)})`,
                      value: {
                        id: p.id,
                        type: p.type,
                        image: p.slider_image,
                        name: p.name,
                      },
                    };
                  })
                : []
            }
            onChange={(option) => {
              regionHandler2(option);
              setSearchData({
                ...searchData,
                ["address_data"]: option,
              });
            }}
          />
          {loadError ? (
            <div>Error loading maps</div>
          ) : !isLoaded ? (
            <div>Loading maps</div>
          ) : (
            <div>
              <GoogleMap
                ref={mapRef}
                mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={center}
                key={changedRadius}
              >
                {regions && regions.length > 0
                  ? regions.map((item) => (
                      <Circle
                        ref={circleRef}
                        options={{
                          strokeColor: "#9A1F60",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: "#9A1F60",
                          fillOpacity: 0.35,
                        }}
                        center={{
                          lng: item.lng,
                          lat: item.lat,
                        }}
                        radius={item.radius * 1000}
                        onLayout={() =>
                          this.setNativeProps({
                            strokeColor: "#FFF000",
                            fillColor: "#FFF000",
                          })
                        }
                      />
                    ))
                  : ""}
              </GoogleMap>
            </div>
          )}
          {regions && regions.length > 0 ? (
            <>
              <p>{t("banner_selected_regions")}</p>
              <RegionCardSelectable
                regions={regions}
                changeRadius={changeRadius}
                handleDeleteRegion={handleDeleteRegion}
                suppliers={suppliers}
                handleChangeSupplier={handleChangeSupplier}
                handleChangeBudget={handleChangeBudget}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <div className={classes.BannerTemplateWrap}>
          <div className={classes.InputFieldBox}>
            <p>{t("banner_select_template")}</p>
            <TemplateCardSelectable
              choosenTemplateId={templateId}
              chooseTemplate={chooseTemplate}
              properties={
                selectedProperties.length > 0
                  ? selectedProperties.join("-")
                  : myPropertyListingData?.rows
                      ?.filter((item) => item.agency_id === id)
                      .map((item) => item.id)
                      .slice(0, 3)
                      .join("-")
              }
              type="Leaderboard"
              quote={quote}
            />
          </div>
        </div>
        <div className={classes.BannerTemplateWrap}>
          <div className={classes.InputFieldBox}>
            <TemplateCardSelectable
              choosenTemplateId={templateId}
              chooseTemplate={chooseTemplate}
              properties={
                selectedProperties.length > 0
                  ? selectedProperties.join("-")
                  : myPropertyListingData?.rows
                      ?.filter((item) => item.agency_id === id)
                      .map((item) => item.id)
                      .slice(0, 3)
                      .join("-")
              }
              type="Mobile"
              quote={quote}
            />
          </div>
        </div>
        <div className={classes.BannerTemplateWrap}>
          <p className={classes.CustomContact}>
            {t("banner_custom_template")}{" "}
            <a href="mailto:custom_template@woningmarkt.nl">
              {t("banner_custom_template_contact")}
            </a>
          </p>
        </div>
        <div className={classes.BannerCreditWrap}>
          <div className={classes.InputFieldBox}>
            <p>{t("banner_select_credit")}</p>
            <div className={classes.BannerSummaryWrap}>
              <p>{t("banner_title")}</p>
              <p>{addBannerData.title}</p>
              <div className={classes.RowBorder}></div>
              <p>{t("banner_selected_regions_summary")}</p>
              <p>
                {regions
                  .map((item) => `${item.name} (+ ${item.radius}km)`)
                  .join(", ")}
              </p>
              <div className={classes.RowBorder}></div>
              <p>{t("banner_runtime")}</p>
              <p>
                {DateTime.fromISO(addBannerData.start_date).toFormat(
                  "dd-LL-yyyy"
                )}{" "}
                -{" "}
                {DateTime.fromISO(addBannerData.end_date).toFormat(
                  "dd-LL-yyyy"
                )}{" "}
                ({" "}
                {
                  DateTime.fromISO(addBannerData.end_date)
                    .diff(DateTime.fromISO(addBannerData.start_date), "days")
                    .toObject()["days"]
                }{" "}
                {t("banner_days")} )
              </p>
              <div className={classes.RowBorder}></div>
            </div>
            <div className={classes.BannerSummaryWrap}>
              <p>{t("banner_costs_per_day")}</p>
              <p>€ {PriceFormatter(costsPerDay, 2)}</p>
              <div className={classes.RowBorder}></div>
              <p>{t("banner_runtime_costs")}</p>
              <p>€ {PriceFormatter(totalCosts, 2)}</p>
              <div className={classes.RowBorder}></div>
            </div>
          </div>
        </div>
        <button
          className={!submitted ? classes.saveButton : classes.disabledButton}
          onClick={AddBannerHandler}
        >
          {t("banner_activate")}
        </button>
      </div>
    </div>
  );
};
export default MyAddBannerLayout;
