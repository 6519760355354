export const LOGIN_API_CALL = "LOGIN_API_CALL";
export const LOGIN_BY_TOKEN_API_CALL = "LOGIN_BY_TOKEN_API_CALL";
export const REGISTER_API_CALL = "REGISTER_API_CALL";
export const FORGOTPASSWORD_API_CALL = "FORGOTPASSWORD_API_CALL";
export const ACTION_SAVE_FORGOT_EMAIL = "ACTION_SAVE_FORGOT_EMAIL";
export const OTP_API_CALL = "OTP_API_CALL";
export const ACTION_SAVE_OTP = "ACTION_SAVE_OTP";
export const RESETPASSWORD_API_CALL = "RESETPASSWORD_API_CALL";
export const MAKE_PROPERTY_FAVOURITE_API_CALL =
  "MAKE_PROPERTY_FAVOURITE_API_CALL";
export const MY_LISTING_API_CALL = "MY_LISTING_API_CALL";
export const ACTION_SAVE_LOGIN_TOKEN = "ACTION_SAVE_LOGIN_TOKEN";
export const ACTION_LATEST_PROPERTY_LISTING_HOME =
  "ACTION_LATEST_PROPERTY_LISTING_HOME";
export const ACTION_SAVE_LATEST_PROPERTY_LISTING_HOME =
  "ACTION_SAVE_LATEST_PROPERTY_LISTING_HOME";
export const ACTION_SAVE_MY_PROPERTY_LISTING =
  "ACTION_SAVE_MY_PROPERTY_LISTING";
  //GET PROPERTY ALL
  export const ACTION_SAVE_ALL_PROPERTY_DATA_LISTING =
  "ACTION_SAVE_ALL_PROPERTY_DATA_LISTING";
export const ACTION_EDIT_ACCOUNT_INFO_API_CALL =
  "ACTION_EDIT_ACCOUNT_INFO_API_CALL";
export const ACTION_LOGOUT_API_CALL = "ACTION_LOGOUT_API_CALL";
export const MY_ORGANISATION_LISTING_API_CALL =
  "MY_ORGANISATION_LISTING_API_CALL";
export const ACTION_SAVE_MY_ORGANISATION_LISTING =
  "ACTION_SAVE_MY_ORGANISATION_LISTING";
export const ACTION_ADD_ORGANISATION_API_CALL =
  "ACTION_ADD_ORGANISATION_API_CALL";
export const ACTION_ADD_ORGANISATION_LOGO_API_CALL =
  "ACTION_ADD_ORGANISATION_LOGO_API_CALL";
export const ACTION_ADD_PROPERTY_STEP_ONE_API_CALL =
  "ACTION_ADD_PROPERTY_STEP_ONE_API_CALL";
export const ACTION_ADD_PROPERTY_STEP_TWO_API_CALL =
  "ACTION_ADD_PROPERTY_STEP_TWO_API_CALL";
export const ACTION_ADD_PROPERTY_STEP_THREE_API_CALL =
  "ACTION_ADD_PROPERTY_STEP_THREE_API_CALL";
export const ACTION_SEARCH_ORGANISATION_API_CALL =
  "ACTION_SEARCH_ORGANISATION_API_CALL";
export const ACTION_SAVE_SEARCH_ORGANISATION =
  "ACTION_SAVE_SEARCH_ORGANISATION";
export const ACTION_JOIN_ORGANISATION_API_CALL =
  "ACTION_JOIN_ORGANISATION_API_CALL";
export const ACTION_REMOVE_JOIN_ORGANISATION_API_CALL =
  "ACTION_REMOVE_JOIN_ORGANISATION_API_CALL";
export const ACTION_REMOVE_SAVE_JOIN_ORGANISATION =
  "ACTION_REMOVE_SAVE_JOIN_ORGANISATION";
export const ACTION_FAVOURITE_PROPERTY_LISTING_API_CALL =
  "ACTION_FAVOURITE_PROPERTY_LISTING_API_CALL";
export const ACTION_EMPTY_SEARCH_ORGANISATION =
  "ACTION_EMPTY_SEARCH_ORGANISATION";
export const ACTION_CHANGE_FAVOURITE = "ACTION_CHANGE_FAVOURITE";
export const ACTION_VIEW_SINGLE_PROPERTY_DETAILS_API_CALL =
  "ACTION_VIEW_SINGLE_PROPERTY_DETAILS_API_CALL";
export const ACTION_SAVE_SINGLE_PROPERTY_DETAILS =
  "ACTION_SAVE_SINGLE_PROPERTY_DETAILS";
export const ACTION_VIEW_BANNER_DETAILS_API_CALL =
  "ACTION_VIEW_BANNER_DETAILS_API_CALL";
export const ACTION_SAVE_BANNER_DETAILS = "ACTION_SAVE_BANNER_DETAILS";
export const ACTION_UPDATE_PROPERTY_VISIBILE_API_CALL =
  "ACTION_UPDATE_PROPERTY_VISIBILE_API_CALL";

export const ACTION_SAVED_FILTER_API_CALL = "ACTION_SAVED_FILTER_API_CALL";
export const ACTION_ADD_SAVED_FILTER_API_CALL =
  "ACTION_ADD_SAVED_FILTER_API_CALL";
export const ACTION_SAVE_FILTER_DATA = "ACTION_SAVE_FILTER_DATA";
export const ACTION_UPDATE_HIDE_PROPERTY = "ACTION_UPDATE_HIDE_PROPERTY";
export const ACTION_ADD_PROPERTY_API_CALL = "ACTION_ADD_PROPERTY_API_CALL";
export const ACTION_ADD_PROPERTY_API_CALL_V1 =
  "ACTION_ADD_PROPERTY_API_CALL_V1";
export const ACTION_ADD_BANNER_API_CALL = "ACTION_ADD_BANNER_API_CALL";
export const ACTION_UPDATE_BANNER_API_CALL = "ACTION_UPDATE_BANNER_API_CALL";
export const ACTION_SAVE_LOGIN_INFO = "ACTION_SAVE_LOGIN_INFO";
export const ACTION_UPDATE_IMAGE_API_CALL = "ACTION_UPDATE_IMAGE_API_CALL";
export const RESET_STATE = "RESET_STATE";
export const ACTION_GET_LANGUAGES_API_CALL = "ACTION_GET_LANGUAGES_API_CALL";
export const ACTION_SAVE_GET_LANGUAGES = "ACTION_SAVE_GET_LANGUAGES";
export const ACTION_CHANGE_LANGUAGE_API_CALL =
  "ACTION_CHANGE_LANGUAGE_API_CALL";
export const ACTION_SAVE_SELECTED_LANGUAGE = "ACTION_SAVE_SELECTED_LANGUAGE";
export const ACTION_GET_ORGANISATION_DETAIL_API_CALL =
  "ACTION_GET_ORGANISATION_DETAIL_API_CALL";
export const ACTION_SAVE_ORGANISATION_DETAIL =
  "ACTION_SAVE_ORGANISATION_DETAIL";
export const ACTION_SET_LOADER = "ACTION_SET_LOADER";
export const ACTION_GET_HOME_BANNER_API_CALL =
  "ACTION_GET_HOME_BANNER_API_CALL";
export const ACTION_SAVE_GET_HOME_BANNER = "ACTION_SAVE_GET_HOME_BANNER";
export const ACTION_REMOVE_IMAGES_API_CALL = "ACTION_REMOVE_IMAGES_API_CALL";
export const ACTION_REMOVE_PROPERTY_PRICE_API_CALL =
  "ACTION_REMOVE_PROPERTY_PRICE_API_CALL";
export const ACTION_HOME_STRAPI_API_CALL = "ACTION_HOME_STRAPI_API_CALL";
export const ACTION_FOOTER_STRAPI_API_CALL = "ACTION_FOOTER_STRAPI_API_CALL";
export const ACTION_SAVE_HOME_STRAPI = "ACTION_SAVE_HOME_STRAPI";
export const ACTION_SAVE_FOOTER_STRAPI = "ACTION_SAVE_FOOTER_STRAPI";
export const ACTION_SAVE_HEADER_STRAPI = "ACTION_SAVE_HEADER_STRAPI";

//get property type
export const ACTION_GET_PROPERTY_TYPE_API_CALL =
  "ACTION_GET_PROPERTY_TYPE_API_CALL";
export const ACTION_SAVE_GET_PROPERTY_TYPE = "ACTION_SAVE_GET_PROPERTY_TYPE";

//get applicable options
export const ACTION_GET_APPLICABLE_OPTIONS_API_CALL =
  "ACTION_GET_APPLICABLE_OPTIONS_API_CALL";
export const ACTION_SAVE_GET_APPLICABLE_OPTIONS =
  "ACTION_SAVE_GET_APPLICABLE_OPTIONS";

//get applicable Cities
export const ACTION_GET_CITIES_API_CALL = "ACTION_GET_CITIES_API_CALL";

//get auto suggested cities
export const ACTION_GET_AUTO_SUGGEST_CITIES_API_CALL =
  "ACTION_GET_AUTO_SUGGEST_CITIES_API_CALL";

//save auto suggested cities
export const ACTION_SAVE_AUTO_SUGGEST_CITY = "ACTION_SAVE_AUTO_SUGGEST_CITY";

export const ACTION_SAVE_GET_CITIES = "ACTION_SAVE_GET_CITIES";

//get Translation List
export const ACTION_SAVE_TRANSLATION_LIST = "ACTION_SAVE_TRANSLATION_LIST";

//get rent prefix and rent postfix
export const ACTION_GET_RENT_PREFIX_API_CALL =
  "ACTION_GET_RENT_PREFIX_API_CALL";
export const ACTION_SAVE_GET_RENT_PREFIX = "ACTION_SAVE_GET_RENT_PREFIX";
export const ACTION_GET_RENT_POSTFIX_API_CALL =
  "ACTION_GET_RENT_POSTFIX_API_CALL";
export const ACTION_SAVE_GET_RENT_POSTFIX = "ACTION_SAVE_GET_RENT_POSTFIX";

//get sell prefix and sell postfix
export const ACTION_GET_SELL_PREFIX_API_CALL =
  "ACTION_GET_SELL_PREFIX_API_CALL";
export const ACTION_SAVE_GET_SELL_PREFIX = "ACTION_SAVE_GET_SELL_PREFIX";
export const ACTION_GET_SELL_POSTFIX_API_CALL =
  "ACTION_GET_SELL_POSTFIX_API_CALL";
export const ACTION_SAVE_GET_SELL_POSTFIX = "ACTION_SAVE_GET_SELL_POSTFIX";
export const ACTION_SAVE_COPY_RIGHT_CONTENT_STRAPI =
  "ACTION_SAVE_COPY_RIGHT_CONTENT_STRAPI";
export const ACTION_SAVE_SOCIAL_ICONS_STRAPI =
  "ACTION_SAVE_SOCIAL_ICONS_STRAPI";
export const ACTION_GET_SLUG_DATA_STRAPI_API_CALL =
  "ACTION_GET_SLUG_DATA_STRAPI_API_CALL";
export const ACTION_SAVE_SLUG_DATA_STRAPI = "ACTION_SAVE_SLUG_DATA_STRAPI";
export const ACTION_SAVE_PROPERTY_TYPE = "ACTION_SAVE_PROPERTY_TYPE";
export const ACTION_APPROVED_ORGANISATIONS_API_CALL =
  "ACTION_APPROVED_ORGANISATIONS_API_CALL";
export const ACTION_SAVE_APPROVED_ORGANISATIONS =
  "ACTION_SAVE_APPROVED_ORGANISATIONS";

//employee flow
export const ACTION_ADD_ORGANISATION_EMPLOYEE_API_CALL =
  "ACTION_ADD_ORGANISATION_EMPLOYEE_API_CALL";
export const ACTION_SAVE_ORGANISATION_EMPLOYEE_LIST =
  "ACTION_SAVE_ORGANISATION_EMPLOYEE_LIST";
export const ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL =
  "ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL";
export const ACTION_SAVE_EMPLOYEE_JOB_TITLE = "ACTION_SAVE_EMPLOYEE_JOB_TITLE";
export const ACTION_REMOVE_ORGANISATION_EMPLOYEE_API_CALL =
  "ACTION_REMOVE_ORGANISATION_EMPLOYEE_API_CALL";
export const ACTION_SAVE_REMOVE_ORGANISATION_EMPLOYEE =
  "ACTION_SAVE_REMOVE_ORGANISATION_EMPLOYEE";

//site settings
export const ACTION_GET_SITE_SETTINGS_API_CALL =
  "ACTION_GET_SITE_SETTINGS_API_CALL";
export const ACTION_SAVE_GET_SITE_SETTINGS = "ACTION_SAVE_GET_SITE_SETTINGS";

//contact api call for view listing page
export const ACTION_CONTACT_US_INFO_API_CALL =
  "ACTION_CONTACT_US_INFO_API_CALL";

//delete my property
export const ACTION_DELETE_MY_PROPERTY_API_CALL =
  "ACTION_DELETE_MY_PROPERTY_API_CALL";
export const ACTION_SAVE_DELETE_MY_PROPERTY = "ACTION_SAVE_DELETE_MY_PROPERTY";

//org account without login
export const ACTION_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN_API_CALL =
  "ACTION_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN_API_CALL";
export const ACTION_CREATE_ORG_ACCOUNT_STEP2_API_CALL =
  "ACTION_CREATE_ORG_ACCOUNT_STEP2_API_CALL";
export const ACTION_SAVE_PDF_LINK = "ACTION_SAVE_PDF_LINK";
export const SAVE_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN =
  "SAVE_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN";
export const ACTION_CREATE_ORGANISATION_ACCOUNT_WITHOUT_LOGIN_API_CALL =
  "ACTION_CREATE_ORGANISATION_ACCOUNT_WITHOUT_LOGIN_API_CALL";

//verfiy user
export const ACTION_VERIFICATION_API_CALL = "ACTION_VERIFICATION_API_CALL";
export const ACTION_SAVE_VERIFICATION_RESPONSE =
  "ACTION_SAVE_VERIFICATION_RESPONSE";
export const ACTION_USER_VERIFIED = "ACTION_USER_VERIFIED";

//mollie payment
export const ACTION_MOLLIE_PAYMENT_API_CALL = "ACTION_MOLLIE_PAYMENT_API_CALL";
export const ACTION_SAVE_MOLLIE_PAYMENT_URL = "ACTION_SAVE_MOLLIE_PAYMENT_URL";

//search org without login
export const ACTION_SEARCH_ORGANISATION_WITHOUT_LOGIN_API_CALL =
  "ACTION_SEARCH_ORGANISATION_WITHOUT_LOGIN_API_CALL";
export const ACTION_SAVE_SEARCH_ORGANISATION_WITHOUT_LOGIN =
  "ACTION_SAVE_SEARCH_ORGANISATION_WITHOUT_LOGIN";

export const BANNER_CAMPAIGNS_API_CALL = "BANNER_CAMPAIGNS_API_CALL";
export const BANNER_TEMPLATES_API_CALL = "BANNER_TEMPLATES_API_CALL";
export const ACTION_SAVE_DEFAULT_PROPERTY_BANNNER_REGION =
  "ACTION_SAVE_DEFAULT_PROPERTY_BANNNER_REGION";

export const ACTION_SAVE_BANNER_CAMPAIGNS_LISTING =
  "ACTION_SAVE_BANNER_CAMPAIGNS_LISTING";
export const ACTION_SAVE_BANNER_TEMPLATES_LISTING =
  "ACTION_SAVE_BANNER_TEMPLATES_LISTING";

export const ACTION_SAVE_IS_PROPERTY_AVAILABLE =
  "ACTION_SAVE_IS_PROPERTY_AVAILABLE";

export const ACTION_DELETE_PROPERTY_BANNER_API_CALL =
  "ACTION_DELETE_PROPERTY_BANNER_API_CALL";

export const ACTION_SAVE_DELETE_PROPERTY_BANNER =
  "ACTION_SAVE_DELETE_PROPERTY_BANNER";

//subscription api
export const ACTION_GET_SUBSCIPTION_API_CALL =
  "ACTION_GET_SUBSCIPTION_API_CALL";
export const ACTION_SAVE_SUBSCIPTION = "ACTION_SAVE_SUBSCIPTION";
export const ACTION_SAVE_SINGLE_SUBSCIPTION_DETAILS =
  "ACTION_SAVE_SINGLE_SUBSCIPTION_DETAILS";
export const ACTION_CREATE_USER_SUBSCIPTION_API_CALL =
  "ACTION_CREATE_USER_SUBSCIPTION_API_CALL";
export const ACTION_GET_SUBSCIPTION_WITH_STATUS_API_CALL =
  "ACTION_GET_SUBSCIPTION_WITH_STATUS_API_CALL";
export const ACTION_SAVE_SUBSCIPTION_WITH_STATUS =
  "ACTION_SAVE_SUBSCIPTION_WITH_STATUS";
export const ACTION_CREATE_ACCOUNT_WITH_SUBSCRIPTION_API_CALL =
  "ACTION_CREATE_ACCOUNT_WITH_SUBSCRIPTION_API_CALL";
export const ACTION_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION_API_CALL =
  "ACTION_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION_API_CALL";
export const ACTION_SAVE_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION =
  "ACTION_SAVE_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION";
export const ACTION_GET_CITY_LIST_API_CALL = "ACTION_GET_CITY_LIST_API_CALL";
export const ACTION_SAVE_GET_CITY_LIST = "ACTION_SAVE_GET_CITY_LIST";
export const ACTION_SAVE_GET_TRANSLATION_LIST =
  "ACTION_SAVE_GET_TRANSLATION_LIST";

//
export const ACTION_GET_TRANSLATION_LIST_API_CALL =
  "ACTION_GET_TRANSLATION_LIST_API_CALL";

//city info
export const ACTION_CITY_INFO_API_CALL = "ACTION_CITY_INFO_API_CALL";
export const ACTION_CITY_INFO_SAVE_DATA = "ACTION_CITY_INFO_SAVE_DATA";

//search background image
export const ACTION_SAVE_SEARCH_BACKGROUND_IMAGE_URL =
  "ACTION_SAVE_SEARCH_BACKGROUND_IMAGE_URL";

//banner recommendations api call

export const ACTION_GET_AGENCY_BANNER_RECOMMENDATIONS_API_CALL =
  "ACTION_GET_AGENCY_BANNER_RECOMMENDATIONS_API_CALL";
export const ACTION_SAVE_AGENCY_BANNER_RECOMMENDATIONS_DATA =
  "ACTION_SAVE_AGENCY_BANNER_RECOMMENDATIONS_DATA";
export const ACTION_GET_AGENCY_BANNER_CLICK_COUNT_API_CALL =
  "ACTION_GET_AGENCY_BANNER_CLICK_COUNT_API_CALL";

//suppliers, titles and prices
export const ACTION_GET_SUPPLIERS_API_CALL = "ACTION_GET_SUPPLIERS_API_CALL";
export const ACTION_SAVE_SUPPLIERS_DATA = "ACTION_SAVE_SUPPLIERS_DATA";

export const MAKE_BANNER_STOP_API_CALL = "MAKE_BANNER_STOP_API_CALL";

export const ACTION_GET_BANNERS_API_CALL = "ACTION_GET_BANNERS_API_CALL";
export const ACTION_SAVE_BANNERS_DATA = "ACTION_SAVE_BANNERS_DATA";
export const ACTION_SEARCH_STATUS = "ACTION_SEARCH_STATUS";
export const ACTION_SAVE_PROPERTY_DATA = "ACTION_SAVE_PROPERTY_DATA";

export const ACTION_MY_INVOICES_API_CALL = "ACTION_MY_INVOICES_API_CALL";
export const ACTION_SAVE_INVOICES_DATA = "ACTION_SAVE_INVOICES_DATA";

export const ACTION_GET_INVOICE_API_CALL = "ACTION_GET_INVOICE_API_CALL";
export const ACTION_SAVE_INVOICE_DATA = "ACTION_SAVE_INVOICE_DATA";

export const actionLoginApiCall = (data) => ({
  type: LOGIN_API_CALL,
  payload: data,
});

export const actionLoginByTokenApiCall = (data) => ({
  type: LOGIN_BY_TOKEN_API_CALL,
  payload: data,
});

export const actionRegisterApiCall = (data) => ({
  type: REGISTER_API_CALL,
  payload: data,
});

export const actionForgotPasswordApiCall = (data) => ({
  type: FORGOTPASSWORD_API_CALL,
  payload: data,
});

export const actionSaveForgotEmail = (data) => ({
  type: ACTION_SAVE_FORGOT_EMAIL,
  payload: data,
});

export const actionOtpApiCall = (data) => ({
  type: OTP_API_CALL,
  payload: data,
});

export const actionSaveLoginToken = (data) => ({
  type: ACTION_SAVE_LOGIN_TOKEN,
  payload: data,
});

export const actionSaveOtp = (data) => ({
  type: ACTION_SAVE_OTP,
  payload: data,
});

export const actionResetPasswordApiCall = (data) => ({
  type: RESETPASSWORD_API_CALL,
  payload: data,
});

export const actionLatestPropertyListingHomeApiCall = (data) => ({
  type: ACTION_LATEST_PROPERTY_LISTING_HOME,
  payload: data,
});

export const actionSaveLatestPropertyListingHome = (data) => ({
  type: ACTION_SAVE_LATEST_PROPERTY_LISTING_HOME,
  payload: data,
});

export const actionSaveMyPropertyListing = (data) => ({
  type: ACTION_SAVE_MY_PROPERTY_LISTING,
  payload: data,
});

export const actionEditAccountInfoApiCall = (data) => ({
  type: ACTION_EDIT_ACCOUNT_INFO_API_CALL,
  payload: data,
});

export const actionLogoutApiCall = (data) => ({
  type: ACTION_LOGOUT_API_CALL,
  payload: data,
});

export const actionMakePropertyFavApiCall = (data) => ({
  type: MAKE_PROPERTY_FAVOURITE_API_CALL,
  payload: data,
});

export const actionMyListingApiCall = (data) => ({
  type: MY_LISTING_API_CALL,
  payload: data,
});

export const actionMyOrganisationListingApiCall = (data) => ({
  type: MY_ORGANISATION_LISTING_API_CALL,
  payload: data,
});

export const actionSaveMyOrganisationListing = (data) => ({
  type: ACTION_SAVE_MY_ORGANISATION_LISTING,
  payload: data,
});

export const actionSavedFilterData = (data) => ({
  type: ACTION_SAVE_FILTER_DATA,
  payload: data,
});

export const actionAddOrganisationApiCall = (data) => ({
  type: ACTION_ADD_ORGANISATION_API_CALL,
  payload: data,
});

export const actionAddOrganisationLogoApiCall = (data) => ({
  type: ACTION_ADD_ORGANISATION_LOGO_API_CALL,
  payload: data,
});

export const actionSavedFilterApiCall = (data) => ({
  type: ACTION_SAVED_FILTER_API_CALL,
  payload: data,
});

export const actionAddSavedFilterApiCall = (data) => ({
  type: ACTION_ADD_SAVED_FILTER_API_CALL,
  payload: data,
});
export const actionAddPropertyStepThreeApiCall = (data) => ({
  type: ACTION_ADD_PROPERTY_STEP_THREE_API_CALL,
  payload: data,
});
export const actionSearchOrganisationApiCall = (data) => ({
  type: ACTION_SEARCH_ORGANISATION_API_CALL,
  payload: data,
});
export const actionSaveSearchOrganisation = (data) => ({
  type: ACTION_SAVE_SEARCH_ORGANISATION,
  payload: data,
});
export const actionEmptySearchOrganisation = (data) => ({
  type: ACTION_EMPTY_SEARCH_ORGANISATION,
  payload: data,
});

export const actionJoinOrganisationApiCall = (data) => ({
  type: ACTION_JOIN_ORGANISATION_API_CALL,
  payload: data,
});
export const actionRemoveJoinOrganisationApiCall = (data) => ({
  type: ACTION_REMOVE_JOIN_ORGANISATION_API_CALL,
  payload: data,
});
export const actionRemoveSaveJoinOrganisation = (data) => ({
  type: ACTION_REMOVE_SAVE_JOIN_ORGANISATION,
  payload: data,
});
export const actionFavouritePropertyListingApiCall = (data) => ({
  type: ACTION_FAVOURITE_PROPERTY_LISTING_API_CALL,
  payload: data,
});
export const actionChangeFavourite = (data) => ({
  type: ACTION_CHANGE_FAVOURITE,
  payload: data,
});
export const actionViewSinglePropertyDetailsApiCall = (data) => ({
  type: ACTION_VIEW_SINGLE_PROPERTY_DETAILS_API_CALL,
  payload: data,
});
export const actionSaveSinglePropertyDetails = (data) => ({
  type: ACTION_SAVE_SINGLE_PROPERTY_DETAILS,
  payload: data,
});
export const actionViewBannerDetailsApiCall = (data) => ({
  type: ACTION_VIEW_BANNER_DETAILS_API_CALL,
  payload: data,
});
export const actionSaveBannerDetails = (data) => ({
  type: ACTION_SAVE_BANNER_DETAILS,
  payload: data,
});
export const actionUpdatePropertyVisibleApiCall = (data) => ({
  type: ACTION_UPDATE_PROPERTY_VISIBILE_API_CALL,
  payload: data,
});
export const actionUpdateHideProperty = (data) => ({
  type: ACTION_UPDATE_HIDE_PROPERTY,
  payload: data,
});
export const actionAddPropertyApiCall = (data) => ({
  type: ACTION_ADD_PROPERTY_API_CALL,
  payload: data,
});
export const actionAddPropertyApiCallv1 = (data) => ({
  type: ACTION_ADD_PROPERTY_API_CALL_V1,
  payload: data,
});

export const actionAddBannerApiCall = (data) => ({
  type: ACTION_ADD_BANNER_API_CALL,
  payload: data,
});
export const actionUpdateBannerApiCall = (data) => ({
  type: ACTION_UPDATE_BANNER_API_CALL,
  payload: data,
});
export const actionBannerCampaignsApiCall = (data) => ({
  type: BANNER_CAMPAIGNS_API_CALL,
  payload: data,
});
export const actionBannerTemplatesApiCall = (data) => ({
  type: BANNER_TEMPLATES_API_CALL,
  payload: data,
});
export const actionSaveBannerCampaignsListing = (data) => ({
  type: ACTION_SAVE_BANNER_CAMPAIGNS_LISTING,
  payload: data,
});
export const actionSaveBannerTemplatesListing = (data) => ({
  type: ACTION_SAVE_BANNER_TEMPLATES_LISTING,
  payload: data,
});
export const actionSaveDefaultPropertyBannerRegion = (data) => ({
  type: ACTION_SAVE_DEFAULT_PROPERTY_BANNNER_REGION,
  payload: data,
});
export const actionSaveLoginInfo = (data) => ({
  type: ACTION_SAVE_LOGIN_INFO,
  payload: data,
});
export const actionUpdateImageApiCall = (data) => ({
  type: ACTION_UPDATE_IMAGE_API_CALL,
  payload: data,
});

export const actionResetState = () => ({
  type: RESET_STATE,
});

export const actionGetLanguagesApiCall = (data) => ({
  type: ACTION_GET_LANGUAGES_API_CALL,
  payload: data,
});

export const actionSaveGetLanguages = (data) => ({
  type: ACTION_SAVE_GET_LANGUAGES,
  payload: data,
});

export const actionChangeLanguageApiCall = (data) => ({
  type: ACTION_CHANGE_LANGUAGE_API_CALL,
  payload: data,
});

export const actionSaveSelctedLanguage = (data) => ({
  type: ACTION_SAVE_SELECTED_LANGUAGE,
  payload: data,
});

export const actionGetOrganisationDetailApiCall = (data) => ({
  type: ACTION_GET_ORGANISATION_DETAIL_API_CALL,
  payload: data,
});

export const actionSaveOrganisationDetail = (data) => ({
  type: ACTION_SAVE_ORGANISATION_DETAIL,
  payload: data,
});

export const actionSetLoader = (data) => ({
  type: ACTION_SET_LOADER,
  payload: data,
});

export const actionGetHomeBannerApiCall = (data) => ({
  type: ACTION_GET_HOME_BANNER_API_CALL,
  payload: data,
});

export const actionSaveGetHomeBanner = (data) => ({
  type: ACTION_SAVE_GET_HOME_BANNER,
  payload: data,
});

export const actionRemoveImageApiCall = (data) => ({
  type: ACTION_REMOVE_IMAGES_API_CALL,
  payload: data,
});

export const actionRemovePropertyPriceApiCall = (data) => ({
  type: ACTION_REMOVE_PROPERTY_PRICE_API_CALL,
  payload: data,
});

export const actionHomeStrapiApiCall = (data) => ({
  type: ACTION_HOME_STRAPI_API_CALL,
  payload: data,
});

export const actionFooterStrapiApiCall = (data) => ({
  type: ACTION_FOOTER_STRAPI_API_CALL,
  payload: data,
});

export const actionSaveHomeStrapi = (data) => ({
  type: ACTION_SAVE_HOME_STRAPI,
  payload: data,
});

export const actionSaveFooterStrapi = (data) => ({
  type: ACTION_SAVE_FOOTER_STRAPI,
  payload: data,
});

export const actionGetPropertyTypeApiCall = (data) => ({
  type: ACTION_GET_PROPERTY_TYPE_API_CALL,
  payload: data,
});

export const actionSaveGetPropertyType = (data) => ({
  type: ACTION_SAVE_GET_PROPERTY_TYPE,
  payload: data,
});

export const actionGetApplicableOptionsApiCall = (data) => ({
  type: ACTION_GET_APPLICABLE_OPTIONS_API_CALL,
  payload: data,
});

export const actionSaveGetApplicableOptions = (data) => ({
  type: ACTION_SAVE_GET_APPLICABLE_OPTIONS,
  payload: data,
});

export const actionGetCitiesApiCall = (data) => ({
  type: ACTION_GET_CITIES_API_CALL,
  payload: data,
});

export const actionGetSuggestedAutoCitiesApiCall = (data) => ({
  type: ACTION_GET_AUTO_SUGGEST_CITIES_API_CALL,
  payload: data,
});

export const actionSaveAutoSuggestCity = (data) => ({
  type: ACTION_SAVE_AUTO_SUGGEST_CITY,
  payload: data,
});
export const actionSaveGetCities = (data) => ({
  type: ACTION_SAVE_GET_CITIES,
  payload: data,
});

export const actionGetRentPrefixApiCall = (data) => ({
  type: ACTION_GET_RENT_PREFIX_API_CALL,
  payload: data,
});

export const actionSaveGetRentPrefix = (data) => ({
  type: ACTION_SAVE_GET_RENT_PREFIX,
  payload: data,
});

export const actionGetRentPostfixApiCall = (data) => ({
  type: ACTION_GET_RENT_POSTFIX_API_CALL,
  payload: data,
});

export const actionSaveGetRentPostfix = (data) => ({
  type: ACTION_SAVE_GET_RENT_POSTFIX,
  payload: data,
});

export const actionGetSellPrefixApiCall = (data) => ({
  type: ACTION_GET_SELL_PREFIX_API_CALL,
  payload: data,
});

export const actionSaveGetSellPrefix = (data) => ({
  type: ACTION_SAVE_GET_SELL_PREFIX,
  payload: data,
});

export const actionGetSellPostfixApiCall = (data) => ({
  type: ACTION_GET_SELL_POSTFIX_API_CALL,
  payload: data,
});

export const actionSaveGetSellPostfix = (data) => ({
  type: ACTION_SAVE_GET_SELL_POSTFIX,
  payload: data,
});

export const actionSaveHeaderStrapi = (data) => ({
  type: ACTION_SAVE_HEADER_STRAPI,
  payload: data,
});

export const actionSaveCopyRightContentStrapi = (data) => ({
  type: ACTION_SAVE_COPY_RIGHT_CONTENT_STRAPI,
  payload: data,
});

export const actionSaveSocialIconsStrapi = (data) => ({
  type: ACTION_SAVE_SOCIAL_ICONS_STRAPI,
  payload: data,
});

export const actionGetSlugDataStrapiApiCall = (data) => ({
  type: ACTION_GET_SLUG_DATA_STRAPI_API_CALL,
  payload: data,
});

export const actionSaveSlugDataStrapi = (data) => ({
  type: ACTION_SAVE_SLUG_DATA_STRAPI,
  payload: data,
});

export const actionSavePropertyType = (data) => ({
  type: ACTION_SAVE_PROPERTY_TYPE,
  payload: data,
});

export const actionApprovedOrganisationsApiCall = (data) => ({
  type: ACTION_APPROVED_ORGANISATIONS_API_CALL,
  payload: data,
});

export const actionSaveApprovedOrganisations = (data) => ({
  type: ACTION_SAVE_APPROVED_ORGANISATIONS,
  payload: data,
});

export const actionAddOrganisationEmployeeApiCall = (data) => ({
  type: ACTION_ADD_ORGANISATION_EMPLOYEE_API_CALL,
  payload: data,
});

export const actionSaveOrganisationEmployeeList = (data) => ({
  type: ACTION_SAVE_ORGANISATION_EMPLOYEE_LIST,
  payload: data,
});

export const actionGetEmployeeJobTitleApiCall = (data) => ({
  type: ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL,
  payload: data,
});

export const actionSaveEmployeeJobTitle = (data) => ({
  type: ACTION_SAVE_EMPLOYEE_JOB_TITLE,
  payload: data,
});

export const actionRemoveOrganisationEmployeeApiCall = (data) => ({
  type: ACTION_REMOVE_ORGANISATION_EMPLOYEE_API_CALL,
  payload: data,
});

export const actionSaveRemoveOrganisationEmployee = (data) => ({
  type: ACTION_SAVE_REMOVE_ORGANISATION_EMPLOYEE,
  payload: data,
});

export const actionGetSiteSettingsApiCall = (data) => ({
  type: ACTION_GET_SITE_SETTINGS_API_CALL,
  payload: data,
});

export const actionSaveGetSiteSettings = (data) => ({
  type: ACTION_SAVE_GET_SITE_SETTINGS,
  payload: data,
});

export const actionContactUsInfoApiCall = (data) => ({
  type: ACTION_CONTACT_US_INFO_API_CALL,
  payload: data,
});

export const actionDeleteMyPropertyApiCall = (data) => ({
  type: ACTION_DELETE_MY_PROPERTY_API_CALL,
  payload: data,
});

export const actionSaveDeleteMyProperty = (data) => ({
  type: ACTION_SAVE_DELETE_MY_PROPERTY,
  payload: data,
});

export const actionCreateOrgAccountWithoutLoginApiCall = (data) => ({
  type: ACTION_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN_API_CALL,
  payload: data,
});

export const actionCreateOrgAccountStep2ApiCall = (data) => ({
  type: ACTION_CREATE_ORG_ACCOUNT_STEP2_API_CALL,
  payload: data,
});

export const actionSavePdfLink = (data) => ({
  type: ACTION_SAVE_PDF_LINK,
  payload: data,
});

export const actionVerificationApiCall = (data) => ({
  type: ACTION_VERIFICATION_API_CALL,
  payload: data,
});

export const actionUserVerified = (data) => ({
  type: ACTION_USER_VERIFIED,
  payload: data,
});

export const actionMolliePaymentApiCall = (data) => ({
  type: ACTION_MOLLIE_PAYMENT_API_CALL,
  payload: data,
});

export const actionSaveMolliePaymentUrl = (data) => ({
  type: ACTION_SAVE_MOLLIE_PAYMENT_URL,
  payload: data,
});

export const actionSearchOrganisationWithoutLoginApiCall = (data) => ({
  type: ACTION_SEARCH_ORGANISATION_WITHOUT_LOGIN_API_CALL,
  payload: data,
});

export const actionSaveSearchOrganisationWithoutLogin = (data) => ({
  type: ACTION_SAVE_SEARCH_ORGANISATION_WITHOUT_LOGIN,
  payload: data,
});

export const actionSaveIsPropertyAvailable = (data) => ({
  type: ACTION_SAVE_IS_PROPERTY_AVAILABLE,
  payload: data,
});

export const actionDeletePropertyBannerApiCall = (data) => ({
  type: ACTION_DELETE_PROPERTY_BANNER_API_CALL,
  payload: data,
});

export const actionSaveDeletePropertyBanner = (data) => ({
  type: ACTION_SAVE_DELETE_PROPERTY_BANNER,
  payload: data,
});

export const actionGetSubscriptionApiCall = (data) => ({
  type: ACTION_GET_SUBSCIPTION_API_CALL,
  payload: data,
});
export const actionSaveSubscription = (data) => ({
  type: ACTION_SAVE_SUBSCIPTION,
  payload: data,
});
export const actionSaveSingleSubscriptionDetails = (data) => ({
  type: ACTION_SAVE_SINGLE_SUBSCIPTION_DETAILS,
  payload: data,
});
export const actionCreateUserSubscriptionApiCall = (data) => ({
  type: ACTION_CREATE_USER_SUBSCIPTION_API_CALL,
  payload: data,
});
export const actionGetSubscriptionWithStatusApiCall = (data) => ({
  type: ACTION_GET_SUBSCIPTION_WITH_STATUS_API_CALL,
  payload: data,
});
export const actionSaveSubscriptionWithStatus = (data) => ({
  type: ACTION_SAVE_SUBSCIPTION_WITH_STATUS,
  payload: data,
});
export const actionSaveCreateOrgAccountWithoutLogin = (data) => ({
  type: SAVE_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN,
  payload: data,
});
export const actionCreateOrganisationAccountWithoutLoginApiCall = (data) => ({
  type: ACTION_CREATE_ORGANISATION_ACCOUNT_WITHOUT_LOGIN_API_CALL,
  payload: data,
});
export const actionCreateAccountWithSubscriptionApiCall = (data) => ({
  type: ACTION_CREATE_ACCOUNT_WITH_SUBSCRIPTION_API_CALL,
  payload: data,
});
export const actionSelfOrganisationListingForSubscriptionApiCall = (data) => ({
  type: ACTION_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION_API_CALL,
  payload: data,
});
export const actionSaveSelfOrgansiationListingForSubscription = (data) => ({
  type: ACTION_SAVE_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION,
  payload: data,
});
export const actionGetCityListApiCall = (data) => ({
  type: ACTION_GET_CITY_LIST_API_CALL,
  payload: data,
});
export const actionSaveGetCityList = (data) => ({
  type: ACTION_SAVE_GET_CITY_LIST,
  payload: data,
});

export const actionGetTranslationListApiCall = (data) => ({
  type: ACTION_GET_TRANSLATION_LIST_API_CALL,
  payload: data,
});

export const actionSaveTranslationList = (data) => ({
  type: ACTION_SAVE_GET_TRANSLATION_LIST,
  payload: data,
});
export const actionCityInfoApiCall = (data) => ({
  type: ACTION_CITY_INFO_API_CALL,
  payload: data,
});

export const actionSaveCityInfo = (data) => ({
  type: ACTION_CITY_INFO_SAVE_DATA,
  payload: data,
});
export const actionSaveSearchBackgroundImageUrl = (data) => ({
  type: ACTION_SAVE_SEARCH_BACKGROUND_IMAGE_URL,
  payload: data,
});
export const actionGetAgencyBannerRecommendationsApiCall = (data) => ({
  type: ACTION_GET_AGENCY_BANNER_RECOMMENDATIONS_API_CALL,
  payload: data,
});
export const actionSaveAgencyBannerRecommendationsData = (data) => ({
  type: ACTION_SAVE_AGENCY_BANNER_RECOMMENDATIONS_DATA,
  payload: data,
});
export const actionGetAgencyBannerClickCountApiCall = (data) => ({
  type: ACTION_GET_AGENCY_BANNER_CLICK_COUNT_API_CALL,
  payload: data,
});
export const actionGetSuppliersApiCall = (data) => ({
  type: ACTION_GET_SUPPLIERS_API_CALL,
  payload: data,
});
export const actionSaveSuppliersData = (data) => ({
  type: ACTION_SAVE_SUPPLIERS_DATA,
  payload: data,
});
export const actionMakeBannerStopApiCall = (data) => ({
  type: MAKE_BANNER_STOP_API_CALL,
  payload: data,
});
export const actionGetBannersApiCall = (data) => ({
  type: ACTION_GET_BANNERS_API_CALL,
  payload: data,
});
export const actionSaveBannersData = (data) => ({
  type: ACTION_SAVE_BANNERS_DATA,
  payload: data,
});
export const actionSavePropertyData = (data) => ({
  type: ACTION_SAVE_PROPERTY_DATA,
  payload: data,
});
export const actionSearchStatus = (data) => ({
  type: ACTION_SEARCH_STATUS,
  payload: data,
});
//GET PROPERTY ALL
export const actionGetPropertyAllDataApiCall = (data) => ({
  type: ACTION_SAVE_ALL_PROPERTY_DATA_LISTING,
  payload: data,
});

export const actionMyInvoicesApiCall = (data) => ({
  type: ACTION_MY_INVOICES_API_CALL,
  payload: data,
});

export const actionSaveInvoicesData = (data) => ({
  type: ACTION_SAVE_INVOICES_DATA,
  payload: data,
});

export const actionGetInvoiceApiCall = (data) => ({
  type: ACTION_GET_INVOICE_API_CALL,
  payload: data,
});

export const actionSaveInvoiceData = (data) => ({
  type: ACTION_SAVE_INVOICE_DATA,
  payload: data,
});

