import React, { useState } from "react";
import HomeSizeIcon from "../../../assets/icons/HomeSizeIcon.svg";
import BedIson from "../../../assets/icons/BedIcon.svg";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import ChartIcon from "../../../assets/icons/Chart.svg";
import classes from "./propertycardmain.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionChangeFavourite,
  actionDeleteMyPropertyApiCall,
  actionMakePropertyFavApiCall,
  actionSaveSinglePropertyDetails,
  actionUpdatePropertyVisibleApiCall,
  actionSaveDefaultPropertyBannerRegion,
  actionGetSuppliersApiCall,
  actionSaveSuppliersData,
} from "../../../Redux/Actions";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import NoDataFound from "../../NoDataFound";
import PriceFormatter from "../../../utils/PriceFormatter";
import { ENERGY_LABEL } from "../../../Constants";
import { useTranslation } from "react-i18next";
import displayPrice from "../../../utils/displayPrice";
import SquareMeterIcon from "../../../assets/icons/SquareMeterIcon.svg";
import defaultImage from "../../../assets/images/defaultImage.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import ConfirmationModal from "../ConfirmationModal";
import ListingViews from "../../../assets/icons/ListingViews.svg";
import ListingFav from "../../../assets/icons/ListingFav.svg";
import { useLoadScript } from "@react-google-maps/api";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const PropertyCardMain = ({ searchSplit, noData }) => {
  const urll = useParams()
  console.log(urll,"urllll")
  const dispatch = useDispatch();
  const myPropertyListingData = useSelector(
    (state) => state.CommonReducer.myPropertyListingData
  );
  console.log(myPropertyListingData,"mypropss")
  const location = useLocation().pathname;
  const IMAGE_BASE_URL = "https://api-test.woningmarkt.nl/uploads/";
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const RemovePropertyHandler = () => {
    let data = {
      id: id,
    };
    let body = { data, navigate };
    dispatch(actionDeleteMyPropertyApiCall(body));
  };

  const libraries = ["places"];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC-Cl8nAWgel11v0YGZjYsmpNSLrOjCxFI",
    libraries,
  });

  const createBanner = async (id) => {
    let regions = [];

    const objects = await myPropertyListingData.rows.filter(
      (item) => item?.id === parseInt(id)
    );
    geocodeByAddress(`${objects[0].address}, ${objects[0].city}`)
      .then((results) => getLatLng(results[0]))
      .then(async ({ lat, lng }) => {
        const newRegion = {
          name: `${objects[0].address}, ${objects[0].postcode} ${objects[0].city}`,
          type: "ZIPCODE",
          id: objects[0].zipcode_id,
          radius: 15,
          lat: lat,
          lng: lng,
        };
        regions.push(newRegion);
        const data = { regions: JSON.stringify(regions) };
        const body = { data, navigate };
        dispatch(actionSaveDefaultPropertyBannerRegion(regions));
        dispatch(actionSaveSuppliersData([]));
        dispatch(actionGetSuppliersApiCall(body));
        navigate(`/add-banner?object=${id}`);
      });
  };

  return (
    <React.Fragment>
      <div className={classes.CardListingWrap}>
        {location == "/search" || location.includes("/view-organisation") ? (
          myPropertyListingData?.rows && myPropertyListingData?.rows?.length > 0 ? (
            myPropertyListingData?.rows
              ?.slice(searchSplit.start, searchSplit.end)
              .map((item) => (
                <div
                  className={
                    location.includes("/view-organisation")
                      ? `${classes.PropertyCardSearch} ${classes.PropertyCardSearchSpace}`
                      : item.hide_property === 1 && location == "/my-listing"
                      ? `${classes.InvisibleCard} ${classes.PropertyCardSearch}`
                      : classes.PropertyCardSearch
                  }
                >
                  <div
                    className={classes.PropertyImg}
                    onClick={() =>
                      navigate(
                        `/view-listing/${
                          item.city &&
                          item.city
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")
                        }/${
                          item.address
                            ? item.address
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                            : `${item.street ? item.street + "-" : ""}${
                                item.housenumber ? item.housenumber + "-" : ""
                              }${item.postcode ? item.postcode + "-" : ""}${
                                item.district ? item.district : ""
                              }`
                                .toLowerCase()
                                .replace(/[^\w-]+/g, "")
                        }/${item.id}`
                      )
                    }
                  >
                    {item.image_list.length > 0 ? (
                      item.image_list.map((val) => {
                        return (
                          <>
                            {item.status &&
                              item.status.toUpperCase() !== "AVAILABLE" && (
                                <div className={classes.PropertyStatus}>
                                  {t(item.status)}
                                </div>
                              )}
                            <img
                              src={
                                val.url_thumb_file.includes("http")
                                  ? val.url_thumb_file
                                  : `${IMAGE_BASE_URL}thumb/${val.url_thumb_file}`
                              }
                              alt={val.original_file_name}
                            />
                          </>
                        );
                      })
                    ) : (
                      <img src={defaultImage} alt="Property" />
                    )}
                  </div>
                  <div className={classes.MainContentWrap}>
                    <div className={classes.PropertyContent}>
                      <h5
                        onClick={() =>
                          navigate(
                            `/view-listing/${
                              item.city &&
                              item.city
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                            }/${
                              item.address
                                ? item.address
                                    .toLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/[^\w-]+/g, "")
                                : `${item.street ? item.street + "-" : ""}${
                                    item.housenumber
                                      ? item.housenumber + "-"
                                      : ""
                                  }${item.postcode ? item.postcode + "-" : ""}${
                                    item.district ? item.district : ""
                                  }`
                                    .toLowerCase()
                                    .replace(/[^\w-]+/g, "")
                            }/${item.id}`
                          )
                        }
                      >
                        {item?.address
                          ? item?.address
                          : `${item?.street} ${item?.housenumber}`}
                      </h5>
                      <span
                        onClick={() =>
                          navigate(
                            `/view-listing/${
                              item.city &&
                              item.city
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                            }/${
                              item.address
                                ? item.address
                                    .toLowerCase()
                                    .replace(/ /g, "-")
                                    .replace(/[^\w-]+/g, "")
                                : `${item.street ? item.street + "-" : ""}${
                                    item.housenumber
                                      ? item.housenumber + "-"
                                      : ""
                                  }${item.postcode ? item.postcode + "-" : ""}${
                                    item.district ? item.district : ""
                                  }`
                                    .toLowerCase()
                                    .replace(/[^\w-]+/g, "")
                            }/${item.id}`
                          )
                        }
                      >
                        {item.postcode} {item.city}
                      </span>
                      <div className={classes.PropertySize}>
                        <div className={classes.PropertySizeCount}>
                          <img src={HomeSizeIcon} alt="Livable area" />
                          <p>{PriceFormatter(item.livable_area)}</p>
                        </div>
                        {item.no_bedroom >= 1 && (
                          <div className={classes.PropertySizeCount}>
                            <img src={BedIson} alt="Bedroom" />
                            <p>{item.no_bedroom}</p>
                          </div>
                        )}
                        <div className={classes.PropertySizeCount}>
                          <img src={SquareMeterIcon} alt="Home plot area" />
                          <p>{PriceFormatter(item?.home_plot_area)}</p>
                        </div>
                      </div>
                      <p className={classes.PriceText}>
                        {" "}
                        {displayPrice(
                          item.is_sell,
                          item.is_rent,
                          item.rent_price,
                          item.rent_postfix,
                          item.sell_price,
                          item.sell_postfix,
                          t
                        )}
                      </p>
                      {item?.agency_detail?.name && (
                        <p
                          className={classes.CardLink}
                          onClick={() => {
                            navigate(
                              `/view-organisation/${
                                item?.agency_detail?.city &&
                                item?.agency_detail?.city
                                  .toLowerCase()
                                  .replace(/ /g, "-")
                                  .replace(/[^\w-]+/g, "")
                              }/${
                                item?.agency_detail?.name &&
                                item?.agency_detail?.name
                                  .toLowerCase()
                                  .replace(/ /g, "-")
                                  .replace(/[^\w-]+/g, "")
                              }/${item.agency_id}`
                            );
                          }}
                        >
                          {item?.agency_detail?.name}
                        </p>
                      )}
                    </div>
                    {!location.includes("/view-organisation") ? (
                      <div className={classes.iconWrap}>
                        {location == "/my-listing" ? (
                          <div className={classes.EditIconWrap}>
                            {item.hide_property === 0 ? (
                              <FaEye
                                onClick={() => {
                                  let data = {
                                    user_id: item.user_id,
                                    property_id: item.id,
                                    hide_property: 1,
                                  };
                                  dispatch(
                                    actionUpdatePropertyVisibleApiCall(data)
                                  );
                                }}
                              />
                            ) : (
                              <FaEyeSlash
                                onClick={() => {
                                  let data = {
                                    user_id: item.user_id,
                                    property_id: item.id,
                                    hide_property: 0,
                                  };
                                  dispatch(
                                    actionUpdatePropertyVisibleApiCall(data)
                                  );
                                }}
                              />
                            )}
                            <img
                              src={EditIcon}
                              onClick={() => {
                                dispatch(actionSaveSinglePropertyDetails(""));
                                navigate(`/edit-listing/${item.id}`);
                              }}
                            />
                          </div>
                        ) : item.is_favourite == 1 ? (
                          <>
                            <AiFillStar
                              onClick={() => {
                                let data = { propertyId: item.id };
                                let body = { data, navigate };
                                dispatch(actionMakePropertyFavApiCall(body));
                                let UpdateFavouriteData = {
                                  id: item.id,
                                  isFavourite: 0,
                                  pathName: location,
                                };
                                dispatch(
                                  actionChangeFavourite(UpdateFavouriteData)
                                );
                              }}
                            />
                            {item.energy_class != "undefined" &&
                              item.energy_class != "" && (
                                <div
                                  style={{
                                    backgroundColor: ENERGY_LABEL.filter(
                                      (val) => val.name == item.energy_class
                                    )[0]?.color,
                                  }}
                                  className={classes.lable}
                                >
                                  {item.energy_class}
                                </div>
                              )}
                          </>
                        ) : (
                          <>
                            <AiOutlineStar
                              onClick={() => {
                                let data = { propertyId: item.id };
                                let body = { data, navigate };
                                dispatch(actionMakePropertyFavApiCall(body));
                                let UpdateFavouriteData = {
                                  id: item.id,
                                  isFavourite: 1,
                                  pathName: location,
                                };
                                dispatch(
                                  actionChangeFavourite(UpdateFavouriteData)
                                );
                              }}
                            />
                            {item.energy_class != "undefined" &&
                              item.energy_class != "" && (
                                <div
                                  style={{
                                    backgroundColor: ENERGY_LABEL.filter(
                                      (val) => val.name == item.energy_class
                                    )[0]?.color,
                                  }}
                                  className={classes.lable}
                                >
                                  {item.energy_class}
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className={classes.ActivityWrap}>
                        <div className={classes.ViewAndFavContainer}>
                          <div className={classes.ListingActivity}>
                            <img src={ListingViews} />
                            <p> x {item?.favourite_count}</p>
                          </div>
                          <div className={classes.ListingActivity}>
                            <img src={ListingFav} alt="Favourite count" />
                            <p> x {item?.view_count}</p>
                          </div>
                        </div>
                        {item.energy_class != "undefined" &&
                          item.energy_class != "" && (
                            <div
                              style={{
                                backgroundColor: ENERGY_LABEL.filter(
                                  (val) => val.name == item.energy_class
                                )[0]?.color,
                              }}
                              className={classes.lable}
                            >
                              {item.energy_class}
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              ))
          ) : !noData ? (
            <NoDataFound />
          ) : null
        ) : myPropertyListingData?.rows?.length > 0 ? (
          myPropertyListingData?.rows?.map((item) => (
            <div
              className={
                !location.includes("/view-organisation")
                  ? item.hide_property === 1 && location == "/my-listing"
                    ? `${classes.InvisibleCard} ${classes.PropertyCard}`
                    : classes.PropertyCard
                  : `${classes.PropertyCardSearch}`
              }
            >
              <div
                className={classes.PropertyImg}
                onClick={() =>
                  navigate(
                    `/view-listing/${
                      item.city &&
                      item.city
                        .toLowerCase()
                        .replace(/ /g, "-")
                        .replace(/[^\w-]+/g, "")
                    }/${
                      item.address
                        ? item.address
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")
                        : `${item.street ? item.street + "-" : ""}${
                            item.housenumber ? item.housenumber + "-" : ""
                          }${item.postcode ? item.postcode + "-" : ""}${
                            item.district ? item.district : ""
                          }`
                            .toLowerCase()
                            .replace(/[^\w-]+/g, "")
                    }/${item.id}`
                  )
                }
              >
                {item.status && item.status.toUpperCase() !== "AVAILABLE" && (
                  <div className={classes.PropertyStatus}>{t(item.status)}</div>
                )}
                {item.image_list.length > 0 ? (
                  item.image_list.map((val) => {
                    return (
                      <img
                        src={
                          val.url_thumb_file.includes("http")
                            ? val.url_thumb_file
                            : `${IMAGE_BASE_URL}thumb/${val.url_thumb_file}`
                        }
                        alt={val.original_file_name}
                      />
                    );
                  })
                ) : (
                  <img src={defaultImage} alt="Property" />
                )}
              </div>
              <div className={classes.MainContentWrap}>
                <div className={classes.PropertyContent}>
                  <h5
                    onClick={() =>
                      navigate(
                        `/view-listing/${
                          item.city &&
                          item.city
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")
                        }/${
                          item.address
                            ? item.address
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                            : `${item.street ? item.street + "-" : ""}${
                                item.housenumber ? item.housenumber + "-" : ""
                              }${item.postcode ? item.postcode + "-" : ""}${
                                item.district ? item.district : ""
                              }`
                                .toLowerCase()
                                .replace(/[^\w-]+/g, "")
                        }/${item.id}`
                      )
                    }
                  >
                    {item.address}
                  </h5>
                  <span
                    onClick={() =>
                      navigate(
                        `/view-listing/${
                          item.city &&
                          item.city
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")
                        }/${
                          item.address
                            ? item.address
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^\w-]+/g, "")
                            : `${item.street ? item.street + "-" : ""}${
                                item.housenumber ? item.housenumber + "-" : ""
                              }${item.postcode ? item.postcode + "-" : ""}${
                                item.district ? item.district : ""
                              }`
                                .toLowerCase()
                                .replace(/[^\w-]+/g, "")
                        }/${item.id}`
                      )
                    }
                  >
                    {item.postcode} {item.city}
                  </span>
                  <div className={classes.PropertySize}>
                    <div className={classes.PropertySizeCount}>
                      <img src={HomeSizeIcon} alt="Livable area" />
                      <p>{PriceFormatter(item.livable_area)}</p>
                    </div>
                    {item.no_bedroom >= 1 && (
                      <div className={classes.PropertySizeCount}>
                        <img src={BedIson} alt="Bedroom" />
                        <p>{item.no_bedroom}</p>
                      </div>
                    )}
                    <div className={classes.PropertySizeCount}>
                      <img src={SquareMeterIcon} alt="Home plot area" />
                      <p>{PriceFormatter(item?.home_plot_area)}</p>
                    </div>
                  </div>
                  <p className={classes.PriceText}>
                    {displayPrice(
                      item.is_sell,
                      item.is_rent,
                      item.rent_price,
                      item.rent_postfix,
                      item.sell_price,
                      item.sell_postfix,
                      t
                    )}
                  </p>
                  {item?.agency_detail?.name && (
                    <p
                      className={classes.CardLink}
                      onClick={() => {
                        navigate(
                          `/view-organisation/${
                            item?.agency_detail?.city &&
                            item?.agency_detail?.city
                              .toLowerCase()
                              .replace(/ /g, "-")
                              .replace(/[^\w-]+/g, "")
                          }/${
                            item?.agency_detail?.name &&
                            item?.agency_detail?.name
                              .toLowerCase()
                              .replace(/ /g, "-")
                              .replace(/[^\w-]+/g, "")
                          }/${item.agency_id}`
                        );
                      }}
                    >
                      {item?.agency_detail?.name}
                    </p>
                  )}
                </div>
                <div className={classes.iconWrap}>
                  {location == "/my-listing" ? (
                    <div className={classes.EditIconWrap}>
                      {item.hide_property === 0 ? (
                        <FaEye
                          onClick={() => {
                            let data = {
                              user_id: item.user_id,
                              property_id: item.id,
                              hide_property: 1,
                            };
                            dispatch(actionUpdatePropertyVisibleApiCall(data));
                          }}
                          title={t("icons_hide_property")}
                        />
                      ) : (
                        <FaEyeSlash
                          onClick={() => {
                            let data = {
                              user_id: item.user_id,
                              property_id: item.id,
                              hide_property: 0,
                            };
                            dispatch(actionUpdatePropertyVisibleApiCall(data));
                          }}
                          title={t("icons_show_property")}
                        />
                      )}
                      <FaEdit
                        src={EditIcon}
                        title={t("icons_edit_property")}
                        onClick={() => navigate(`/edit-listing/${item.id}`)}
                      />
                      <RiDeleteBin6Line
                        title={t("icons_delete_property")}
                        onClick={() => {
                          setId(item.id);
                          setShowModel(true);
                        }}
                      />
                      <img
                        src={ChartIcon}
                        alt={t("icons_create_banner")}
                        title={t("icons_create_banner")}
                        onClick={() => createBanner(item?.id)}
                      />
                    </div>
                  ) : item.is_favourite == 1 ? (
                    <AiFillStar
                      onClick={() => {
                        let data = { propertyId: item.id };
                        let body = { data, navigate };
                        dispatch(actionMakePropertyFavApiCall(body));
                        let UpdateFavouriteData = {
                          id: item.id,
                          isFavourite: 0,
                          pathName: location,
                        };
                        dispatch(actionChangeFavourite(UpdateFavouriteData));
                      }}
                    />
                  ) : (
                    <AiOutlineStar
                      onClick={() => {
                        let data = { propertyId: item.id };
                        let body = { data, navigate };
                        dispatch(actionMakePropertyFavApiCall(body));
                        let UpdateFavouriteData = {
                          id: item.id,
                          isFavourite: 1,
                          pathName: location,
                        };
                        dispatch(actionChangeFavourite(UpdateFavouriteData));
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoDataFound />
        )}
      </div>
      <ConfirmationModal
        showmodel={showModel}
        handleClose={() => setShowModel(false)}
        size={"sm"}
        onClickYesButton={RemovePropertyHandler}
        title={`${t("are_you_sure_you_want_to_remove")}?`}
      />
    </React.Fragment>
  );
};
export default PropertyCardMain;
