import React, { useEffect } from "react";
import Footer from "../../components/layouts/Footer/Index";
import Header from "../../components/layouts/Header/Index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actionLoginByTokenApiCall } from "../../Redux/Actions";

const MailAddBannerPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const object = params.get("object")
    const url = `/add-banner?object=${object}`;
    let data = { token: params.get("uid") };
    let body = { data, navigate, url, object };
    dispatch(actionLoginByTokenApiCall(body));
  });
  return (
    <React.Fragment>
      <Header />
      Een ogenblik geduld aub je gegevens worden opgehaald...
      <Footer />
    </React.Fragment>
  );
};

export default MailAddBannerPage;
